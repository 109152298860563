import React, { Fragment } from 'react'
import Routers from '../../routers/Routers';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';



import whatapps1 from '../../assets/images/png.png';
import whatapps from '../../assets/images/what.png';





const Layout = () => {
  return (
    <>
         <a href="tel:+92 319 1793992" target="_blank"><img src={whatapps1} alt="" style={{position : "fixed" ,zIndex : "999999", top : "75%" , right : "15px",width:"50px",height : "50px"}}/></a>
                  
                  <a href="https://wa.me/923191793992?text=Hi *Rent a Car*! I need more info about Car Rent in Islamabad https://rentacars.site" target="_blank"><img src={whatapps} alt="" style={{position : "fixed" ,zIndex : "999999", top : "67%" , right : "10px",width:"60px",height : "60px"}}/></a>
             
             
   <Fragment>
    <Header/>
    <div>
        <Routers/>
    </div>
    <Footer/>
   </Fragment>
    </>
   
  )
}

export default Layout;