import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import carData from '../assets/data/carData';
import Helmet from '../components/Helmet/Helmet';
import BookingForm from '../components/UI/BookingForm';
import PaymentMethod from '../components/UI/PaymentMethod';

const CarDetails = () => {
    const {slug} = useParams();
    console.log(slug)
    carData.map((item) => {
        console.log(item.Product_Name ==slug)
        // item.Product_Name === slug
    
    });
    const singleCarItem = carData.find((item) => item.Product_Name === slug);

    useEffect(() => {
        window.scrollTo(0, 0);
    },[singleCarItem])
    return (
       <Helmet title ={singleCarItem.Product_Name}>
        <section>
            <Container>
                <Row>
                    <Col lg='6'>
                        <img src={singleCarItem.Product_Image_Upload} alt="" className='w-100' />
                    </Col>

                    <Col lg='6'>
                        <div className="car__info">
                            <h2 className='section__title'> {singleCarItem.Product_Name}</h2>
                            <div className="d-flex align-items-center gap-5 mb-4 mt-3">
                                <h6 className="rent__price fw-bold fs-4">Rs.{singleCarItem.Product_Price_Discounted}/Day</h6>
                                <span className="d-flex align-items-center gap-2">
                                    <span style = {{color: "#f9a826"}}>
                                        <i class="ri-star-s-fill"></i>
                                        <i class="ri-star-s-fill"></i>
                                        <i class="ri-star-s-fill"></i>
                                        <i class="ri-star-s-fill"></i>
                                        <i class="ri-star-s-fill"></i>
                                    </span>

                                    (5 ratings)
                                </span>
                            </div>
                         
                            <div className="d-flex align-items-center mt-3" style={{columnGap: '4rem'}}>
                                <span className="d-flex align-items-center gap-1 section__description"><i class="ri-roadster-line" style = {{color: "#f9a826"}}></i>{singleCarItem.ac ? "Ac Car" : "Non Ac"}
                                </span>

                                <span className="d-flex align-items-center gap-1 section__description"><i class="ri-settings-2-line" style = {{color: "#f9a826"}}></i>{singleCarItem.petrol}
                                </span>

                                <span className="d-flex align-items-center gap-1 section__description"><i class="ri-timer-flash-line" style = {{color: "#f9a826"}}></i>{singleCarItem.cc}cc
                                </span>

                            </div>

                            <div className="d-flex align-items-center mt-3" style={{columnGap: '2.8rem'}}>
                                <span className="d-flex align-items-center gap-1 section__description"><i class="ri-map-pin-line" style = {{color: "#f9a826"}}></i>{singleCarItem.luggage} Luggage
                                </span>

                                <span className="d-flex align-items-center gap-1 section__description"><i class="ri-wheelchair-line" style = {{color: "#f9a826"}}></i>{singleCarItem.person} Person
                                </span>

                                <span className="d-flex align-items-center gap-1 section__description"><i class="ri-building-2-line" style = {{color: "#f9a826"}}></i>{singleCarItem.doors} Doors
                                </span>

                            </div>
                            <div className='payment mt-5'>
                                <button style={{marginRight:"20px"}}><a href="tel:+92 319 179 3992" style={{color:"white",textDecoration:"none"}}>Book Now</a></button>
                                <button><a href={"https://wa.me/923191793992?text=Hi *Rent a Car*! I need more info about "+singleCarItem.Product_Name} style={{color:"white",textDecoration:"none"}}>Reverse Now</a></button>
                            </div>
                            <div className='payment mt-5'>
                            </div>

                        </div>

                    <br />
                    <br />
                    </Col>
                    <h3>Description : </h3>
   <p className='section__description'>
                                <div dangerouslySetInnerHTML={{__html: singleCarItem.Product_Long_Notes}} />
                            </p>

                    {/* <Col lg='7' className='mt-5'>
                        <div className="booking-info mt-5">
                            <h5 className='mb-4 fw-bold'>Booking Information</h5>
                            <BookingForm/>

                        </div>
                    
                    </Col>
                    <Col lg="5" className="mt-5">
              <div className="payment__info mt-5">
                <h5 className="mb-4 fw-bold ">Payment Information</h5>
                <PaymentMethod />
              </div>
            </Col> */}
                </Row>
            </Container>
        </section>

       </Helmet>
    );
};

export default CarDetails;